.editbutton{
    width: 100%;
  }
  .editbutton.submit-button{
    margin-top: 1rem;
    width: 100%;
  }
  
  .delete-button{
    background-color: red;
    color: white;
  }
  .delete-button:hover{
    background-color: #ff0000;
    color: white;
  }
  .addworks-addbtn{
    width: 100%;
    height: 3rem;
    margin-bottom: 1rem;
  }