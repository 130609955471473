/* .marquee-container {
    overflow: hidden;
    position: absolute;
    top: 6rem;
    width: 100%;
    transform: translateY(0);
    writing-mode: vertical-lr;
    text-orientation: mixed;
    left: 2rem;
}

.marquee-content {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
.marquee-text {
    animation: scroll 30s linear infinite;
}
.marquee-text:nth-child(2){
    animation: scroll2 30s linear infinite;
} */

  @keyframes scroll {
    from {
      transform: translateY(-300%);
    }
    to {
      transform: translateY(200%);
    }
  }
  
  @keyframes scroll2 {
    from {
      transform: translateY(-300%);
    }
    to {
      transform: translateY(200%);
    }
  }
  
  .logo-wall {
    overflow: hidden;
    position: absolute;
    top: 6rem;
    transform: translateY(0);
    writing-mode: vertical-lr;
    text-orientation: mixed;
    flex-direction: row;
    left: 2rem;
    font-family: Montserrat, sans-serif;
    font-optical-sizing: auto;
    font-size: .9rem;
    font-style: normal;
    font-weight: 200;
    color: #686868;
    .logo-wrapper {
      display: flex;
      animation: scroll 10s linear infinite;
      /* animation-delay: -10s; */
      white-space: nowrap;
      flex-direction: row;
      align-items: center;
      &:nth-child(2) {
        animation: scroll2 10s linear infinite;
        animation-delay: -1s;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
  