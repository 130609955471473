:where(.css-dkbvqv).ant-modal .ant-modal-footer >.ant-btn+.ant-btn {
  margin-left: 0;
}
:where(.css-dkbvqv).ant-modal .ant-modal-footer >.ant-btn+.ant-btn {
  margin-inline-start:none;
}

.table-row-editing {
    background-color: #fefefe;
  }

.editbutton{
  width: 100%;
  margin-left: 0;
}
.editbutton.submit-button{
  margin-top: 1rem;
  width: 100%;
  /* right: 0.5rem; */
}

.cancel-btn{
  margin-top: 1rem;
  padding: 0;
  width: 100%;
  /* right: 0.5rem; */
}
.admin-content{
  width: fit-content;
}
.ant-modal-content{
  /* min-width: 20rem; */
  width: 47rem;
  left: -50px;
  flex: 1;
}
.ql-toolbar.ql-snow {
  flex-direction: row;
}
.delete-button{
  background-color: red;
  color: white;
}
.delete-button:hover{
  background-color: #ff0000;
  color: white;
}
.react-datepicker-popper{
  width:100%;
}
.react-datepicker__month-container{
  width:100%;
}
.react-datepicker__header__dropdown{
  display: flex;
}
.react-datepicker__day-names{
  flex-direction: row;
  justify-content: center;
}
.react-datepicker__month{
  display: flex;
  align-items: center;
}
.react-datepicker__week{
  flex-direction: row;
}
.react-datepicker__input-container{
  display: flex;
  justify-content: center;
}
