/* login- */
.login-border-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 345px;
    height: 185px;
    border-radius: 10px;
    overflow: hidden;
}

.login-border-wrapper::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%; /* 進一步增加尺寸 */
    height: 300%; /* 進一步增加尺寸 */
    transform: translate(-50%, -50%);
    background: linear-gradient(60deg, 
                                red, orange, yellow, green, blue, indigo, violet, 
                                red, orange, yellow, green, blue, indigo, violet);
    background-size: 200% 200%; /* 調整漸變尺寸 */
    border-radius: 10px;
    animation: rotateGradient 3s linear infinite;
}

.login-login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    background: #000000;
    position: relative;
    z-index: 1;
}

@keyframes rotateGradient {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
}


/* 表單內元素設定 */
.login-input[type="text"],
.login-input[type="password"] {
    width: 93%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.login-button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    background-color: #3f51f3;
    color: white;
    cursor: pointer;
}

.login-button:hover {
    background-color: #007bff;
}