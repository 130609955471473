:root{
    width: 100vw;
    height: 100vh;
}
div{
    display: flex;
    flex-direction: column;
    margin: 0;
}
.WorksDetail-main{
    color: white;
}
/* WorksDetail- */
.WorkDetail-main-view{
    margin-left: 10rem;
    margin-right: 10rem;
}
/* 文字設定 */
.WorkDetail-text{
    display: flex;
    color: #FFFFFF;
    flex-direction: row;
    align-items: stretch;
}

.WorkDetail-text h1{
    display: flex;
    position: relative;
    justify-content:left;
    align-items: center;
    width: 25%;
    height: 100%;
    right: -10rem;
    font-size: 2rem;
    word-break: break-word;
    color: #666666;
    margin-top: 1.9em;
    margin-bottom: 0.8em;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
}
.wd-categories{
    display: flex;
    position: relative;
    justify-content:right;
    width: 65rem;
    height: auto;    
    align-items: baseline;
    vertical-align: baseline;
}
.wd-cate{
    flex-direction: row;
    flex: 1;
    height: 100%;
    position: absolute;
    vertical-align: baseline;
    top: 0; 
    right: 0;
    display: flex;
    justify-content: space-evenly;
    margin: 1.5em 0 0;
}
.wd-cate p{
    margin: 0 0 0 1.5rem;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
}

.WorkDetail-text p{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
}
.WorkDetail-creditinfo{
    margin-left: 10rem;
    margin-right: 10rem;
    padding-top: 3rem;
    margin-bottom: 3rem;
    height: 15rem;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; /* 從左至右排序 */
    align-items: flex-start;
}
.WorkDetail-creditinfo-in{
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    max-width: 35rem;
    /* flex: 1; */
    height: 100%;
}
.WorkDetail-creditinfo-item{
    flex: 1;
    height: 100%;
    vertical-align: baseline;
}
.wd-y{
    width: 8rem;
}
.wd-cr{
    /* block-size: fit-content; */
    max-width: 50rem;

}
.wd-year{
    flex: 1;
    max-width: 10rem;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
}
.wd-credit{
    display:flex;
    font-size: 2.5em;
    margin-bottom: 0;
    margin-top: 10rem;
}
.WorkDetail-creditinfo-item p{
    flex: 1;
    align-items: flex-end;
    display: flex;
    justify-content:left;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
    color: #686868;
}

.WorkDetail-creditinfo-item:last-child{
    /* width: calc(+2rem); */
}
.wd-year{
    margin-top: 0;
    font-size: small;
}
.WorkDetail-md{
    min-width: 20rem;
    flex: 1;
}
.WorkDetail-md p{
    color: #898989;
    margin: 0;
    flex-wrap: wrap
}
/* 圖片設定 */
.WorkDetail-img{
    width: 100%;
    height: 50%;
    display: flex; /* 啟用 Flexbox */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
}
.WorkDetail-img img{
    border-top-width: 2rem;
    width: 80%;
    height: 50%;
    object-fit: cover;
    overflow: hidden;
}
.WorkDetail-ml{
    margin-top: 0.5rem;
}

/* Skeleton Screen */
@keyframes skeletonLoading {
    0% {
        background-color: #eee;
    }
    50% {
        background-color: #ddd;
    }
    100% {
        background-color: #eee;
    }
}

/* Skeleton Screen */
#skeleton{
    animation: skeletonLoading 1.5s infinite ease-in-out;
    width: 100%; 
    height: 100%; 
    background-color: grey;
}
.skeleton-img-hidden{
    display: none;
}

/* 半畫面螢幕(210px至 1200px) */
@media only screen and (min-width: 210px) and (max-width: 768px) {
    .WorkDetail-main-view{
        margin-left: 0;
        margin-right: 0;
    }
    .WorkDetail-img{
        position: relative;
        width: 90%;
        display: flex; /* 啟用 Flexbox */
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
        left: 1rem;
    }
    .WorkDetail-img img {
        width: 90%; /* 圖片寬度設為容器的100% */
        height: auto; /* 圖片高度自動調整以保持比例 */
        object-fit: cover; /* 覆蓋模式，以防止圖片失真 */
      }

    .WorkDetail-text h1{
        font-size: 1.5rem;
        color: #8299a0;
        width: 100%;
        right: -2rem;
    }
    .WorkDetail-title{
        display: flex;
        position: relative;
        justify-content:left;
        align-items: center;
    }
}
