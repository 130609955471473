/* admin- */
.admin-container{
  width: 100%;
  position: relative;
  display:flex;
  flex-direction: row;
  object-fit: contain;
}
.admin-left{
  width: 25%;
  height: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.left80{
  width: 88%;
  height: 100%;
  overflow: hidden;
}

.admin-title{
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  align-items: center;
}
.admin-content{
  width: 80%;
  height: 100%;
  position: relative;
  /* 使右側元素始終填滿*/
}
.admin-buttons{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.adminbtn{
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  position: relative;
  justify-content: center;
  font-size: large;
}
.adminbtn-logout{
  position: relative;
  margin-top: 5rem;
}