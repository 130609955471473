:root{
    width: 100vw;
    height: 100vh;
}
div{
    display: flex;
    flex-direction: column;
    margin: 0;
}
.WorksOverview-main{
    color: white;
}
.WorksOverview-main-content{
    margin-top: 0.7rem;
    display: flex;
    width: 100%;
    justify-content: space-between; /* 將子元素分散對齊到兩端 */
    align-items: flex-start; /* 確保子元素從父元素的頂部開始對齊 */
    flex-direction: row;
}
.disabled{
    color:rgb(0, 0, 0);
}
.WorksOverview-disable{
    display: none;
}

@keyframes scroll {
    from {
      transform: translateY(-300%);
    }
    to {
      transform: translateY(200%);
    }
  }
  
  @keyframes scroll2 {
    from {
      transform: translateY(-300%);
    }
    to {
      transform: translateY(200%);
    }
  }
  
  .logo-wall-info {
    overflow: hidden;
    position: absolute;
    top: 6rem;
    transform: translateY(0);
    writing-mode: vertical-lr;
    text-orientation: mixed;
    flex-direction: row;
    left: 2rem;
    font-family: Montserrat, sans-serif;
    font-optical-sizing: auto;
    font-size: .9rem;
    font-style: normal;
    font-weight: 200;
    color: #686868;
    height: 24rem;
    .logo-wrapper {
      display: flex;
      animation: scroll 15s linear infinite;
      /* animation-delay: -10s; */
      white-space: nowrap;
      flex-direction: row;
      align-items: center;
      &:nth-child(2) {
        animation: scroll2 15s linear infinite;
        animation-delay: -1s;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
/* 上面為版權調整 */


/* 下側設定 */
.WorksOverview-main-filter{
    position: absolute;
    padding-top: 40px;
    width: 9.5em;
    height: 25.5em;
    top:27rem;
    left: 1.5rem;
    /* justify-content: flex-start; */
    display: flex;
    padding-left: 1em;
    /* margin-left: 5.7rem; */
    /* margin-right: 0.3em; */
    /* margin-top: 2rem; */
    /* border: #666666 solid 1px; */
    transition: border 0.3s ease;
    color: #666666;
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-size: 0.7rem;
}
.WorksOverview-filter{
    margin-top: 0.5rem;
    width: 100%;
    color: #666666;
    margin-left: -1rem;
}
.WorksOverview-main-filter-year{
    font-size: 0.85rem;
}
.WorksOverview-title-hr{
    position: absolute;
    top: 48.7rem;
    left: 1.8rem;
    width: 5.7em;
    border: 0.1px solid #444444;
}
.WorksOverview-title{
    overflow: scroll;
    position: absolute;
    top: 51.5rem;
    left: 1.8rem;
    width: 6em;
    color: #666666;
}
.WorksOverview-title-link{
    margin-bottom: 0.3rem;
}
.WorksOverview-title-in{
    width: 100%;
    min-height: 3rem;
    max-height: 4rem;
    height: fit-content;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    color: #666666;
    font-size: 0.7rem;
    overflow: auto;
}
.wo-span{
    white-space: pre-wrap;
}
.wo-n{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    color: #666666;
    font-size: 1rem;
    margin-bottom: 0.08em;
}

.wo-w{
    display: block;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    color: #666666;
    font-size: 0.65rem;
    padding-top: 0.2em;
    height: 3rem;
    word-wrap: break-word; /* 允許在單詞內換行 */
    white-space: normal; /* 使空格和換行表現正常 */
}


.WorksOverview-main-filter-category{
    margin-bottom: 0.4em;
}
.WorksOverview-main-filter-categories{
    margin-bottom: 1.2rem;
}
.WorksOverview-main-filter-year{
    margin-bottom: 0.2em;
}
.WorksOverview-main-filter-toggle {
    position: absolute;
    top: 8px;
    right: 17px;
    cursor: pointer;
    font-size: 20px;
    transition: left 0.7s ease;
}
.WorksOverview-main-filter-close {
    color: #666666;
}
.WorksOverview-main-filter.closed {
    border: none;
}
.WorksOverview-main-filter.closed .WorksOverview-main-filter-toggle {
    left: 10px;
    right: auto;
}
.WorksOverview-main-filter-open{
    font-size: x-large;
    position: absolute;
    top:calc(5vh + 4.9rem);
}


.WorksOverview-main-filter-open {
    color: #666666;
}

.WorksOverview-main-side {
    /* justify-content: flex-end; */
    width: calc(100% - 3%);
    height: max-content;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 3fr)); /* 根據需要調整 */
    grid-column-gap: 1.45rem;
    grid-auto-rows: minmax(1rem, 12rem);
    grid-row-gap: 7.75rem;
    box-sizing: border-box;
    padding: 2rem 20px 20px 1em;
    margin-left: 10.2em;
    margin-right: 2em;
}
.WorksOverview-main-filter-open .WorksOverview-main-side {
    width: calc(100% - 2rem);
}
.WorksOverview-work-item-title{
    color: white;
}

/* 作品項目布局 */
.WorksOverview-work-item {
    /* margin-left: 2vw;
    margin-top: 2vw; */
    position: relative;
    width: 100%; /* 或根據需求設置固定寬度 */
    height: 280px; /* 設置固定高度，根據需求調整 */
    overflow: hidden; /* 超出部分隱藏 */
}

.WorksOverview-work-item-img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.WorksOverview-work-item-img img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover; /* 圖片填滿或縮放以適應容器 */
}

.WorksOverview-work-item-title {
    position: absolute;
    bottom: -15px; /* 根據需求調整 */
    left: 5px; /* 根據需求調整 */
    font-size: small;
    /* 可能需要更多樣式來符合設計 */
}

/* 圖片灰階馬賽克效果以及文字的動畫 */

.copyright-notice-overview{
    position: absolute;
    top: 33%;
    left: 2rem;
    transform: translateY(-50%);
    writing-mode: vertical-lr;
    text-orientation: mixed;
    font-size: 14px;
    color: #666666;
    z-index: 100;
}


/* 響應式布局 */
@media (max-width: 850px) {
    .WorksOverview-works-overview {
        flex-direction: column; /* 子元素垂直堆疊 */
    }
    .WorksOverview-main-side {
        order: 2; /* 放在 .left-side 下方 */
        /* 這裡可以保持原有的設置，或根據需要進行調整 */
        padding-right: 0;
        margin: 0;
        grid-column-gap: 2rem;
        grid-auto-rows: minmax(12rem, 12rem);
    }
}
@media (min-width: 768px) {
    .WorksOverview-main-filter-open .WorksOverview-main-side {
        width: calc(100% - 13em - 5em);
    }
}
/* Skeleton Screen */
.skeleton{
    width: 100%; 
    height: 300%;
    animation: skeletonLoading 1.5s infinite ease-in-out;
    background: #666666 repeating-linear-gradient(
            45deg,
            #666666,
            #666666 10px,
            #777777 10px,
            #777777 20px
    );
    filter: blur(5px); /* 根据需要调整模糊值 */
}
.skeleton-img-hidden{
    display: none;
}
.wo-svg{
    visibility: hidden;
    color: transparent;
    text-shadow: #fadfdf 0 0 6px;
    user-select: none;
    flex:1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.pixelate-skeleton {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    filter: url(#pixelate);
    background-color: #666666;
    background-image: url('../static/inz.png');
    z-index: 10;
    display: block;
}

.pixelate-img-hidden {
    display: none;
}


@keyframes skeletonLoading {
    0% {
        background-color: #eee;
    }
    50% {
        background-color: #8f8f8f;
    }
    100% {
        background-color: #eee;
    }
}
/* 螢幕尺寸 */
@media only screen and (min-width: 992px) and (max-width: 1189px) {
    .WorksOverview-main-side {
        grid-column-gap: 2rem;
    }
}
@media only screen and (min-width: 850px) and (max-width: 1173px) {
    .WorksOverview-main-side {
        row-gap: 13rem;
    }
    .WorksOverview-works-item {
        height: 15rem;
    }
}
@media only screen and (min-width: 318px) and (max-width: 791px) {
    .WorksOverview-works-item {
        width: 100vw;
    }
    .WorksOverview-works{
        width: 90vw;
    }
}    