:root{
    width: 100vw;
    height: 100vh;
}
div{
    display: flex;
    flex-direction: column;
    margin: 0;
}
.ldbg-main{
    color: white;
}
/* ldbg- */
.ld-img{
    /* top: -5px; */
    position: relative;
    width: 97%;
    height: 95vh;
    /* overflow: hidden; */
    top:-2em;
    z-index: -1;
}


/* main videoplayer */
.ldbg-main-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 2rem - 5vh);
}
.ldbg-player{
    flex-grow: 1;
    width: 100%;
    height: 100%;
    position: relative;
}
.ldbg-react-player{
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
video{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.filter {
    position: fixed;
    width: 150px;
    aspect-ratio: 1;
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
    pointer-events: none;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    -webkit-backdrop-filter: url(#pixelate);
            backdrop-filter: url(#pixelate);
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
    z-index: 9999;
  }

  .pixelate-image {
    position: relative;
    display: inline-block;
  }
  
  .pixelate-image .pixelated-image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .pixelate-image:hover .pixelated-image {
    filter: url(#pixelate);
  }