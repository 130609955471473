.table-row-editing {
    background-color: #fa9e9e;
  }

/* type="primary" selector */
.infobtn {
    width: 100%;
}
.infobtn.submit-btn{
    margin-top: 1rem;
    width: 100%;
    /* right: 0.5rem; */
  }

.infodmtable{
  /* width: 90%; */
  margin-bottom: 1rem;
  object-fit:contain;
  display: flex;
  flex-direction: column;
}
.infomainbtn{
    width: 100%;
    height: 3rem;
    margin-bottom: 1rem;
  }
 .btngroup{
    padding: 0;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
 }
 .infodmcbtn{
    width: 50%;
 }
 .textbox{
    width: 100%;
    margin-top: 1rem;
    height: fit-content;
 }
 .textbox.title-tb{
    height: 3rem;
 }
 .textbox.text-tb{
    height: 10rem;
    
 }
 .admin-content{
   flex: 1;
 }