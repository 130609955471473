/* Contact- */
:root{
    width: 100vw;
    height: 100vh;
}
div{
    display: flex;
    flex-direction: column;
    margin: 0;
}
.Contact-main{
    color: white;
    height: 100%;
}
.Contact-form-container{
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.Contact-form {
    margin-bottom: 5rem; /* mb-20 */
    width: min(100%, 38rem); /* w-[min(100%,38rem)] */
    text-align: center; /* text-center */
    position: relative;
}

.Contact-form p {
    color: #4a5568; /* text-gray-700 */
    margin-top: -1.5rem; /* -mt-6 */
}

.Contact-form a {
    text-decoration: underline; /* underline */
}

.Contact-form form {
    margin-top: 2.5rem; /* mt-10 */
    display: flex;
    flex-direction: column; /* flex flex-col */
    font-family: Montserrat, sans-serif;
    font-optical-sizing: auto;
    font-size: .9rem;
    font-style: normal;
    font-weight: 200;
}

.Contact-form input,
.Contact-form textarea {
    height: 2rem; /* h-14 for input */
    padding: 1rem; /* px-4 */
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.75rem; /* rounded-lg */
    border: 1px solid #ffffff; /* borderBlack */
    background-color: rgba(255, 255, 255, 0.8); /* dark:bg-white dark:bg-opacity-80 */
    transition: all 0.3s ease; /* transition-all */
    font-family: "EB Garamond", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.Contact-form input:focus,
.Contact-form textarea:focus {
    background-color: rgba(255, 255, 255, 1); /* dark:focus:bg-opacity-100 */
    outline: none; /* dark:outline-none */
}

.Contact-form textarea {
    height: 13rem; /* h-52 */
    margin: 1rem 0; /* my-3 */
}
.Contact-form textarea:hover{
    background-color: #ffffff;    
}

.Contact-form button{
    margin-top: 1rem; /* mt-4 */
    height: 3.5rem; /* h-14 */
    border-radius: 0.75rem; /* rounded-lg */
    border: 1px solid #ffffff; /* borderBlack */
    background-color: rgba(255, 255, 255, 0.8); /* dark:bg-white dark:bg-opacity-80 */
    transition: all 0.3s ease; /* transition-all */
    font-family: "EB Garamond", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2em;
}
.Contact-form button:hover{
    background-color: rgb(107, 213, 255); /* dark:hover:bg-opacity-100 */
    animation:infinite-spinning 1s infinite;
}
.Contact-info {
    margin-top: 5rem; /* mt-20 */
    display: flex;
    flex-direction: column; /* flex flex-col */
    align-items: center; /* items-center */
    justify-content: center; /* justify-center */
    text-align: center; /* text-center */
}
.Contact-info p{
    color: #686868 ; /* text-blue-500 */
    font-family: "EB Garamond", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

@media screen and (max-width:625px) {
    .Contact-form {
        width: min(100%, 20rem);
        /* left: calc(50% - 10rem); */
    }
}