.Nav-navbar {
    /* justify-content: space-between; */
    /* padding-top: 1rem; */
    padding-right: 0;
    flex-direction: row;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 8vh;
    justify-content: flex-start;
    background-color: #000000;
    margin-bottom: 1rem;
    /* margin-top: 1rem; */
    z-index: 1;
}
.Nav-logo{
    display: flex;
    flex-direction: row;
    width: 7.5%;
    padding-top: 5rem;
    height: 8vh;
    /* left: -10rem; */
    margin-right: 0.3em;
}
.Nav-logo-img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.Nav-a{
    width: 100%;
    justify-content: flex-start;
    cursor: crosshair;
}
/*  */
.Nav-navclick .Nav-a span {
    cursor: crosshair !important;
  }

  .Nav-navclick .Nav-a {
    position: relative;
    display: inline-block;
    color: white;
    transition: color 0.1s;
  }
  
  .Nav-navclick .Nav-a span {
    position: relative;
    z-index: 1;
  }
  
  .Nav-navclick .Nav-a span::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    top: 0.25em;
    height: 65%;
    background-color: white;
    transition: width 0.1s;
    z-index: -1;
  }
  
  .Nav-navclick .Nav-a:hover {
    color: black;
  }
  
  .Nav-navclick .Nav-a:hover span::before {
    width: 100%;
  }
  /*  */
.Nav-logo a img{
    height: 100%;
}
.Nav-navclick{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    /* justify-content: space-between; */
    align-items: flex-end;
    padding-left: 1rem;
    width: 15%;
    height: 6vh;
    margin-right: calc(100vw - 31%);
    font-family: "EB Garamond", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    a{
        span{
            color: #686868
        }
    }
}

.Nav-svg{
    object-fit: contain;
    display: flex;
    flex-direction: row;
    width: 7%;
    height: 7vh;
    /* padding-left: 1rem; */
    align-items: flex-end;
}
.nav-be{
    position: static;
    left: 5em;
    margin-left: 1.5em;
    width: fit-content;
}
.Nav-svg-be{
    /* width: 50%; */
    height: 7vh;
    object-fit: contain;
}
.Nav-svg-ig{
    padding-top: 20rem;
    /* width: 50%; */
    height: 7vh;
    object-fit: contain;
}
.nav-i{
    width: 85%;    
}


/* 行動裝置適應 */
/* 小型手機（小於 600px） */
@media only screen and (max-width: 599px) {
    .Nav-navbar{
        padding-left: 1rem;
    }
    .Nav-logo{
        display: flex;
        flex-direction: row;
        width: 40%;
        height: 5vh;
        margin-right: 5vw;
    }
    .Nav-logo-img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .Nav-navclick{
        display: flex;
        flex-direction: row;
        justify-content:space-around;
        align-items: center;
        width: 280%;
        height: 5vh;
        position: relative;
        left: 2rem;
    }
    .Nav-separate{
        position: relative;
        align-items: center;
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        height: 5vh;
        left: 2rem;
    }
    .Nav-svg{
        position: relative;
        left: 2rem;
        object-fit: contain;
        display: flex;
        flex-direction: row;
        width: 30rem;
        height: 5vh;
        padding-left: 1rem;
    }
}

/* 小尺寸平板（600px 至 768px） */
@media only screen and (min-width: 600px) and (max-width: 767px) {
    .Nav-logo{
        display: flex;
        flex-direction: row;
        width: 40%;
        height: 5vh;
        margin-right: 20%;
    }
    .Nav-logo-img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .Nav-navclick{
        display: flex;
        flex-direction: row;
        justify-content:space-around;
        align-items: center;
        width: 250%;
        height: 5vh;
    }
    .Nav-svg{
        object-fit: contain;
        display: flex;
        flex-direction: row;
        width: 30rem;
        height: 5vh;
        padding-left: 1rem;
    }
}

/* 平板電腦（768px 至 992px） */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .Nav-logo{
        display: flex;
        flex-direction: row;
        width: 40%;
        height: 5vh;
        margin-right: 20%;
    }
    .Nav-logo-img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .Nav-navclick{
        display: flex;
        flex-direction: row;
        justify-content:space-around;
        align-items: center;
        width: 140%;
        height: 5vh;
    }
    .Nav-svg{
        object-fit: contain;
        display: flex;
        flex-direction: row;
        width: 30rem;
        height: 5vh;
        padding-left: 1rem;
    }
}

/* 筆記型電腦（992px 至 1189px） */
@media only screen and (min-width: 992px) and (max-width: 1189px) {
    .Nav-logo{
        display: flex;
        flex-direction: row;
        width: 25%;
        height: 5vh;
        margin-right: 50%;
    }
    .Nav-logo-img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .Nav-navclick{
        display: flex;
        flex-direction: row;
        justify-content:space-around;
        align-items: center;
        width: calc(80%);
        height: 5vh;
    }
    .Nav-svg{
        object-fit: contain;
        display: flex;
        flex-direction: row;
        width: 30rem;
        height: 5vh;
        padding-left: 1rem;
    }
}
/* 半畫面螢幕(1189px至 1450px) */
@media only screen and (min-width: 1190px) and (max-width: 1450px) {
    .Nav-navbar {
        /* justify-content: space-between; */
        padding: 1rem 0 1rem 2rem;
        flex-direction: row;
        display: flex;
        align-items: flex-end;
        width: calc(100% - 2rem);
        height: 5vh;
        justify-content: center;
        background-color: #000000;
    }
    .Nav-logo{
        display: flex;
        flex-direction: row;
        width: 10%;
        height: 5vh;
        margin-right: 55%;
    }
    .Nav-logo-img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .Nav-navclick{
        display: flex;
        flex-direction: row;
        justify-content:space-around;
        align-items: center;
        width: calc(55%);
        height: 5vh;
    }
    .Nav-separate{
        align-items: center;
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        height: 5vh;
    }
    .Nav-svg{
        object-fit: contain;
        display: flex;
        flex-direction: row;
        width: 30rem;
        height: 5vh;
        padding-left: 1rem;
    }
    .Nav-svg-be{
        width: 40%;
        height: 5vh;
        object-fit: contain;
    }
    .Nav-svg-ig{
        width: 40%;
        height: 5vh;
        object-fit: contain;
    }
    .Nav-a{
        color: white;
        text-decoration: none;
    }
    .Nav-a:hover{
        color: white;
        text-decoration: underline;
    }
    .Nav-a:visited{
        color: white;
        text-decoration: none;
    }
    .Nav-a:active{
        color: white;
        text-decoration: none;
    }
}


/* 滾動條整體樣式 */
/* ::-webkit-scrollbar {
    width: 8px; /* 滾動條寬度 */
/* } */

/* 滾動條軌道 */
/* ::-webkit-scrollbar-track {
    background-color: #000; /* 滾動條軌道背景色 */
    /* border-radius: 4px; /* 滾動條軌道圓角 */
/* } */ 

/* 滾動條滑塊 */
/* ::-webkit-scrollbar-thumb {
    background-color: #fff; /* 滾動條滑塊背景色 */
    /* border-radius: 4px; 滾動條滑塊圓角 */
/* }  */

/* 滾動條滑塊在 hover 狀態下 */
/* ::-webkit-scrollbar-thumb:hover {
    background-color: #aaa; /* 滾動條滑塊在 hover 狀態下的背景色 */
/* } */ 
::-webkit-scrollbar {
    display: none;
}