h1{
    color: white;
}
.info-main{
    width: 100%;
    height: calc(100vh - 5vh - 2rem);
}
.info-title{
    align-items: center;
}
main{
    color: white;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    min-height: 100vh;
    max-height: fit-content;
}
.info-main{
    height: 94vh;
    top:calc(100vh - 6rem);
    align-items: center;
    overflow-y: hidden; /* 隐藏垂直滚动条*/
    overflow-x: hidden; /* 隐藏水平滚动条*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position:initial;
}
.bkimg{
    position: absolute;
    left: 0;
    top:0;
    z-index: -1;
    width: 100%;
    height: max-content;
}
.left{
    position: relative;
    width: 28rem;
    height: 100%;
    margin-left: 6.6rem;
    margin-top: 9%;
}
.leftin{
    /* display: flex; */
    margin-bottom: 0.3rem;
    /* height: 50rem; */
    position: relative;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
    font-size: small;
    .lefth{  
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        height: 2rem;
        .title{
            font-family: "Montserrat", sans-serif;
            font-optical-sizing: auto;
            font-weight: 100;
            font-style: normal;
            font-size: 1.8em;
            margin: 0;
            position:absolute ;
            left: 0;
            top: 0.2em;
        }
        .ench{
            position: absolute;
            top: 0.5rem;
            right: 0;
            background-color:black;
            color: #686868;
            width: 5rem;
            box-shadow: none;
            border: 1px solid black;
        }
    }
    .left_in{
        height: fit-content;
        margin-bottom: 2rem;
        div{
            position: relative;
        }
        .info-word{
            padding-bottom: 0.2rem;
        }
    }
}
.right{
    position: relative;
    width: 50rem;
    margin-right: 10rem;
    display:flex;
    height: 94vh;
    flex-direction: column;
    .info{
        position: relative;
        top: 10%;
        margin-bottom: 4rem;
        font-size: large;
        width: 55rem;
        height: 25rem;
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 100;
        font-style: normal;
        p{
            line-height:1.5em;
        }
    }
    .ench{
        position: relative;
        background-color:black;
        color: #686868;
        top: -0.5em;
        width: 5rem;
        left: 61em;
        box-shadow: none;
        border: 1px solid black;
    }
    .tel{
        position: absolute;
        bottom: 15rem;
        right: -5rem;
        text-align: right;
        font-size: large;
        a:first-child{
            margin-bottom: 1rem;
        }
    }
    .link{
        margin-bottom: 2rem;
        text-align: right;
        position: absolute;
        bottom: 4rem;
        right: -5rem;
        a:nth-child(n+1){
            margin-bottom: 0.5rem;
        }
    }
}
a:link {
    color: #686868;
    text-decoration: none;
}
a:visited{
    color: #686868;
}
a:hover {
    color: #686868;
}
a:active {
    color: #686868;
}
